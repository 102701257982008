<template>
  <el-table
    border
    :data="tableData"
    style="margin-top:10px">
    <el-table-column
      prop="major"
      label="专业"
      width="180">
    </el-table-column>
    <el-table-column
      prop="credits"
      label="学分">
    </el-table-column>
  </el-table>
</template>

<script>
    export default {
      data() {
        return {
          tableData: []
        }
      },
      mounted: function () {
        this.$net.userInfo().then(({ data }) => {
          this.tableData=[{
            major: data.user.major,
            credits: data.user.credits
          }]
        });
      },
    }
  </script>